import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // 三个基础页面
  {
    path: "/layout",
    name: "layout",
    redirect: "/home",
    component: () => import("../views/layout"),

    children: [
      // 权限页面
      {
        path: "/home",
        name: "home",
        component: () => import("../views/home"),
        id: 100,
        meta: {
          name: "系统首页",
          icon: "el-icon-s-home",
          roles: ["*"],
        },
      },
      {
        path: "/goods",
        name: "goods",
        component: () => import("../views/goods"),
        id: 101,
        meta: {
          name: "商品管理",
          title: "商品管理",
          icon: "el-icon-goods",
          roles: ["*"],
        },
      },
      {
        path: "/addGoods",
        name: "addGoods",
        hidden: true,
        component: () => import("../views/goods/details/addGoods.vue"),
        id: 1011,
        pid: 101,
        meta: {
          name: "添加商品",
          title: "添加商品",
          roles: ["*"],
        },
      },
      {
        path: "/category",
        name: "category",
        component: () => import("../views/category"),
        id: 108,
        meta: {
          name: "分类管理",
          title: "分类管理",
          icon: "el-icon-goods",
          roles: ["*"],
        },
      },
      {
        path: "/addCategory",
        name: "addCategory",
        hidden: true,
        component: () => import("../views/category/detail/addCategory.vue"),
        id: 1081,
        pid: 108,
        meta: {
          name: "添加分类",
          title: "添加分类",
          roles: ["*"],
        },
      },

      {
        path: "/order",
        name: "order",
        component: () => import("../views/order"),
        id: 102,
        meta: {
          name: "订单管理",
          icon: "el-icon-s-order",
          roles: ["*"],
        },
      },
      {
        path: "/orderDetails",
        name: "orderDetails",
        hidden: true,
        component: () => import("../views/order/details/orderDetails.vue"),
        id: 1021,
        pid: 102,
        meta: {
          name: "订单详情",
          icon: "el-icon-s-order",
          roles: ["*"],
        },
      },
      {
        path: "/log",
        name: "log",
        component: () => import("../views/log"),
        id: 103,
        meta: {
          name: "日志管理",
          icon: "el-icon-bank-card",
          roles: ["*"],
        },
      },
      {
        path: "/roles",
        name: "roles",
        component: () => import("../views/roles"),
        id: 104,
        meta: {
          name: "角色管理",
          icon: "el-icon-user",
          roles: ["*"],
        },
      },
      {
        path: "/addRoles",
        name: "addRoles",
        hidden: true,
        component: () => import("../views/roles/details/addRoles.vue"),
        id: 1041,
        pid: 104,
        meta: {
          name: "添加角色",
          icon: "el-icon-user",
          roles: ["*"],
        },
      },
      {
        path: "/userDetail",
        name: "userDetail",
        hidden: true,
        component: () => import("../views/roles/details/userDetail.vue"),
        id: 1042,
        pid: 104,
        meta: {
          name: "用户详情",
          icon: "el-icon-user",
          roles: ["*"],
        },
      },
      // {
      //   path: "/stock",
      //   name: "stock",
      //   component: () => import("../views/stock"),
      //   id: 105,
      //   meta: {
      //     name: "库存管理",
      //     icon: "el-icon-user",
      //     roles: ["*"],
      //   },
      // },
      // {
      //   path: "/editStock",
      //   name: "editStock",
      //   hidden: true,
      //   component: () => import("../views/stock/details/editStock.vue"),
      //   id: 1051,
      //   pid: 105,
      //   meta: {
      //     name: "编辑库存",
      //     icon: "el-icon-user",
      //     roles: ["*"],
      //   },
      // },
      // {
      //   path: "/outbound",
      //   name: "商品出库",
      //   hidden: true,
      //   component: () => import("../views/stock/details/Outbound.vue"),
      //   id: 1052,
      //   pid: 105,
      //   meta: {
      //     name: "商品出库",
      //     icon: "el-icon-user",
      //     roles: ["*"],
      //   },
      // },
      {
        path: "/advert",
        name: "advert",
        component: () => import("../views/advert"),
        id: 106,
        meta: {
          name: "广告管理",
          icon: "el-icon-user",
          roles: ["*", "运营"],
        },
      },
      {
        path: "/addAdvert",
        name: "addAdvert",
        hidden: true,
        component: () => import("../views/advert/details/addAdvert.vue"),
        id: 1061,
        pid: 106,
        meta: {
          name: "添加广告",
          icon: "el-icon-user",
          roles: ["*"],
        },
      },
      {
        path: "/bookcase",
        name: "bookcase",
        component: () => import("../views/bookcase"),
        id: 107,
        meta: {
          name: "书柜管理",
          title: "书柜管理",
          icon: "el-icon-user",
          roles: ["*", "管理员"],
        },
      },
      {
        path: "/addBookcase",
        name: "添加书柜",
        hidden: true,
        component: () => import("../views/bookcase/details/addBookcase.vue"),
        id: 1071,
        pid: 107,
        meta: {
          name: "添加书柜",
          title: "添加书柜",
          icon: "el-icon-user",
          roles: ["*"],
        },
      },
      {
        path: "/listedBooks",
        name: "listedBooks",
        hidden: true,
        component: () => import("../views/bookcase/listedBooks.vue"),
        id: 1072,
        pid: 107,
        meta: {
          name: "书柜详情",
          title: "书柜详情",
          icon: "el-icon-user",
          roles: ["*"],
        },
      }
    ],
  },
  {
    path: "/",
    name: "login",
    component: () => import("../views/login/index1.vue"),
    hidden: true,
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/login/register.vue"),
    hidden: true,
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/404.vue"),
    hidden: true,
  },
  {
    path: "*",
    name: "404",
    component: () => import("../views/404.vue"),
    hidden: true,
  },
];

const router = new VueRouter({
  routes,
});

// // 方式一:获取完整路由
// // 从sessionStorage中获取路由
// const serverRoutes = sessionStorage.getItem("serverRoutes");
// if (serverRoutes) {
//   const array = JSON.parse(serverRoutes);
//   addServerRoutes(array);
// }
// // 重置路由
// export function resetRouter() {
//   router.matcher = new VueRouter({ routes }).matcher;
// }
// // 动态添加路由
// export function addServerRoutes(array) {
//   console.log(router.getRoutes());
//   for (const { path, name, icon, hidden, component } of array) {
//     console.log(path, name, icon, hidden, component);
//     if (component !== null) {
//       router.addRoute("layout", {
//         path: path,
//         name: name,
//         icon: icon,
//         hidden: hidden,
//         component: () => import(`@/views/${component}`),
//       });
//     }
//   }
//   console.log(router.getRoutes());
// }

export default router;
