import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    routes: [],
    route: [],
    result: ["home"],
  },
  getters: {},
  mutations: {
    addRoutes(state, data) {
      state.routes = data;
    },
    initRoute(state, array) {
      if (!state.route.includes(array)) {
        state.route.push(array);
      }
    },
    initResult(state, array) {
      if (!state.result.includes(array)) {
        state.result.push(array);
      }
    },
    resetRoute(state) {
      state.route = [];
    },
    resetResult(state) {
      state.result = ["home"];
    },
  },
  actions: {},
  modules: {},
});
