import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import { postRequest } from "./utils/api";
import { putRequest } from "./utils/api";
import { getRequest } from "./utils/api";
import { deleteRequest } from "./utils/api";
// 复制
import Clipboard from "clipboard";

import moment from "moment";
Vue.prototype.$moment = moment;

Vue.prototype.Clipboard = Clipboard;
Vue.use(Element);
Vue.prototype.axios = axios;
Vue.config.productionTip = false;

Vue.prototype.postRequest = postRequest;
Vue.prototype.putRequest = putRequest;
Vue.prototype.getRequest = getRequest;
Vue.prototype.deleteRequest = deleteRequest;

// 暂时用朝闻自习的接口调用
// 路由导航守卫

router.beforeEach((to, from, next) => {
  let title = "新华智能书房";
  // 判断用户是否登录,如果未登录,跳转到登录页
  if (window.sessionStorage.getItem("token")) {
    next();
  } else {
    // next('/')
    // 如果是登录页,则直接放行
    if (to.path == "/" || to.path == "/register") {
      next();
    }
    // 不是登录页的话,跳转到登录页
    else {
      console.log('跳转到登录页')
      next({
        path: "/",
      });
    }
  }

  // 设置页面标题
  if (to.meta.title) {
    title = to.meta.title + " - 新华智能书房";
  }
  document.title = title;
});

// 对王闯接口
// 对接接口路由导航守卫
// router.beforeEach((to, from, next) => {
//   const whiteList = ["/layout", "/home"];
//   // 判断用户是否登录,如果未登录,跳转到登录页
//   if (window.sessionStorage.getItem("token")) {
//     let tokens = window.sessionStorage.getItem("roles");
//     tokens = JSON.parse(tokens)
//     let arr = to.meta.roles;
//     arr = arr + "";
//     arr = arr.split(",");
//     console.log(arr);

//     for (var i = 0; i < tokens.length; i++) {
//       let token = tokens[i];
//       var flag = arr.includes(token);
//       if (flag || whiteList.includes(to.path)) {
//         console.log("我要去", to.path);
//         next();
//       }
//       if (to.path == "/") {
//         next({
//           path: "/layout",
//         });
//       }
//     }

//     // next();
//   } else {
//     // next('/')
//     // 如果是登录页,则直接放行
//     if (to.path == "/") {
//       next();
//     }
//     // 不是登录页的话,跳转到登录页
//     else {
//       next({
//         path: "/",
//       });
//     }
//   }
// });

// 时间日期格式化
Date.prototype.Format = function (fmt) {
  const o = {
    "M+": this.getMonth() + 1, // 月份
    "d+": this.getDate(), // 日
    "h+": this.getHours(), // 小时
    "m+": this.getMinutes(), // 分
    "s+": this.getSeconds(), // 秒
    "q+": Math.floor((this.getMonth() + 3) / 3), // 季度
    S: this.getMilliseconds(), // 毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (let k in o) {
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  }
  return fmt;
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
