import axios from "axios";
import { Message } from "element-ui";
import router from "../router";

// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    if (window.sessionStorage.getItem("token")) {
      // 朝闻自习室对接
      // config.headers['Authorization'] = window.sessionStorage.getItem('tokenStr');
      // 王闯对接
      console.log("调用请求头");
      config.headers["satoken"] = window.sessionStorage.getItem("token");
      // config.headers['headerAdminId'] = window.sessionStorage.getItem('id');
    }
    console.log("请求头外部");
    return config;
  },
  (error) => {
    console.log(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  (success) => {
    // console.log("我是success", success);
    // 成功发送请求到后端
    if (success.status && success.status == 200) {
      if (success.data.status != 200) {
        console.log("!=200");
        if (success.data.status == 502) {
          console.log(502);
          // window.sessionStorage.removeItem("roles");
          // window.sessionStorage.removeItem("token");
          // window.sessionStorage.removeItem("permissions");
          // Message.error({ message: "尚未登录,请登录" });
          router.replace("/");
          // return
        }
        Message.error({ message: success.data.msg });
        return;
      }

      if (success.data.message) {
        Message.success({ message: success.data.msg });
      }
    }
    return success.data;
  },
  (error) => {
    if (error.response.status == 504 || error.response.status == 404) {
      Message.error({ message: "接口调用失败" });
    } else if (error.response.status == 403) {
      Message.error({ message: "权限不足,请联系管理员" });
    } else if (error.response.status == 401) {
      Message.error({ message: "尚未登录,请登录" });
      router.replace("/");
    } else {
      if (error.response.data.message) {
        Message.error({ message: error.response.data.msg });
      } else {
        Message.error({ message: "未知错误" });
      }
    }
    return;
  }
);

// let base = `http://192.168.3.92:8099`
// let base = `https://zwzx.hellomyweb.fun`
// let base = `http://152.136.132.170:8099`
// let base = `http://localhost:8099`
// 发布用这个
let base = `/api`;
// 测试用这个
// let base = ``;
//传送json格式的post请求
export const postRequest = (url, params) => {
  return axios({
    method: "post",
    url: `${base}${url}`,
    data: params,
  });
};
//传送json格式的put请求
export const putRequest = (url, params) => {
  return axios({
    method: "put",
    url: `${base}${url}`,
    data: params,
  });
};
//传送json格式的get请求
export const getRequest = (url, params) => {
  return axios({
    method: "get",
    url: `${base}${url}`,
    data: params,
  });
};
//传送json格式的delete请求
export const deleteRequest = (url, params) => {
  return axios({
    method: "delete",
    url: `${base}${url}`,
    data: params,
  });
};
